body {
  margin: 0;
  color: #a8aeba;
  background-color: #1B1E24;
  font-size: 14px;
  // font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN',
  //   'Hiragino Sans', Meiryo, sans-serif;
  font-family: 'Noto Sans JP', sans-serif;
  font-feature-settings: 'palt' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
}

body.en {
  // font-family: 'Arial', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'Meiryo', sans-serif;
  // strong,
  // b,
  // .bold,
  // .font-weight-bold {
  //   font-family: 'Arial Bold', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'Meiryo', sans-serif;
  // }
  font-family: 'Noto Sans JP', sans-serif;
  strong,
  b,
  .bold,
  .font-weight-bold {
    font-family: 'Noto Sans JP', sans-serif;
  }
}

body.ja {
  font-family: 'Noto Sans JP', sans-serif;
  strong,
  b,
  .bold,
  .font-weight-bold {
    font-family: 'Noto Sans JP', sans-serif;
  }
}

body.zh {
  // font-family: 'Microsoft YaHei', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'Meiryo', sans-serif;
  // strong,
  // b,
  // .bold,
  // .font-weight-bold {
  //   font-family: 'Microsoft YaHei Bold', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'Meiryo', sans-serif;
  // }
  font-family: 'Noto Sans JP', sans-serif;
  strong,
  b,
  .bold,
  .font-weight-bold {
    font-family: 'Noto Sans JP', sans-serif;
  }
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

.application {
  width: 100%;
  min-height: 100%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

&::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}

&::-webkit-scrollbar-track {
  background: #f8f8f8;
  transition: all 0.2s;
}

&::-webkit-scrollbar-thumb {
  background: #8a8aa0;
  border-radius: 100px;
  transition: all 0.2s;
}

&::-webkit-scrollbar-thumb:hover {
  background: #8a8aa0;
  transition: all 0.2s;
}

.sortby-dropdown {
  .ant-dropdown-menu-item {
    padding: 0;
    cursor: not-allowed;

    .menu-item {
      padding: 5px 12px;
      cursor: pointer;

      &.active {
        user-select: none;
        pointer-events: none;
        opacity: 0.2;
        cursor: not-allowed;
      }
    }
  }
}

.modal-galley-item {
  width: 100% !important;
  height: 100%;
  transform-origin: unset !important;
  padding: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  position: fixed;
  z-index: 999999;
  background-color: rgba(18, 19, 26, 0.7);

  .ant-modal-content {
    background: transparent;
    border-radius: 0 !important;
    box-shadow: none;

    .ant-modal-close {
      right: 20px;
      background: transparent;

      .ant-modal-close-x {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        .icon-close {
          width: 22px;
          height: 24px;
        }
      }
    }

    .ant-modal-body {
      background: transparent;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal-galley-item {
    .ant-modal-content {
      .ant-modal-close {
        .ant-modal-close-x {
          font-size: 20px;
        }
      }
    }
  }
}

.sp {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }

  .sp {
    display: block !important;
  }
}

#web-body {
  padding-top: 0;
}

.transfer-icon {
  filter: brightness(0) invert(1);
}

.ant-dropdown-menu {
  box-shadow: 0px 0px 10px rgba(232, 189, 219, 0.3) !important;
  background-color: #1b1e24 !important;
  border-radius: 10px 10px 10px 10px !important;
  .ant-dropdown-menu-item {
    background-color: transparent !important;
  }
  .ant-dropdown-menu-title-content {
    padding: 2px 5px;
    color: #fff !important;
  }

  .ant-dropdown-menu-title-content:hover {
    background-color: #5A5D73 !important;
    border-radius: 10px 10px 10px 10px;
  }
}

.ant-dropdown-menu.menu-category li {
  color: #fff !important;
}

::selection {
  background: #3cc8fc !important;
}
.ant-select-item {
  &:hover {
    background-color: #d6d6d6;
  }
}
.ant-select-item-option-content {
  color: #282828;
  &:hover {
    opacity: 0.5;
  }
}

.ant-popover {
  border-radius: 8px !important;
}
.ant-popover-arrow-content {
  margin: 0;
  box-shadow: 0px 4px 100px 0px #93989a66 !important;

  &::before {
    background-color: #ffffff !important;
  }
}

.ant-popover-content {
  box-shadow: 0px 4px 100px 0px #93989a66 !important;
  background-color: #ffffff;
  border-radius: 10px;

  .ant-popover-inner-content {
    padding: 4px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 100px 0px #93989a66 !important;
    border: 1.5px solid #dbdbdb;
  }
  .ant-popover-inner {
    background-color: transparent;
  }
}

.ant-dropdown {
  background-color: #1b1e24 !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.ant-select-selector {
  border: none;
  box-shadow: none;
  background-color: transparent !important;
  .ant-select-selection-item {
    color: #a8aeba;
  }
  .ant-select-selection-placeholder {
    color: #a8aeba;
  }
  .ant-select-arrow {
    color: #fff;
  }
}
.ant-select-dropdown {
  padding: 20px;
  background: #1b1e24 !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25) !important;
  border-radius: 20px 0 20px 20px !important;
  // min-width: 250px !important;
  .ant-select-item,
  .ant-select-item-option,
  .ant-select-item-option-active,
  .ant-select-item-option-selected {
    background-color: transparent !important;
  }
  .ant-select-item-option {
    .ant-select-item-option-content {
      margin-left: 10px;
      color: #a8aeba !important;
      font-weight: 400;
      font-size: 14px;
    }
    &::before {
      content: '';
      left: 0;
      top: 0;
      bottom: 0;
      padding: 5px;
      border: 1px solid #a8aeba;
      background-clip: content-box;
      border-radius: 999px;
      width: 22px;
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    &::before {
      border: 1px solid #045afc;
      background-color: #045afc;
    }
  }
  .ant-select-item-option-active {
    background-color: transparent;
    .ant-select-item-option-content {
      background-color: transparent;
      // color: #045afc !important;
      font-weight: 400;
      font-size: 14px;
    }
  }
  // .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  //   box-shadow: none !important;
  //   border: none !important;
  // }
}

.container {
  max-width: 1540px;
  padding-left: 40px;
  padding-right: 40px;
  @media screen and (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.product-detail {
  .ant-popover-content,
  .ant-popover-inner,
  .ant-popover-inner-content {
    border-radius: 20px;
  }
}

.no-wrap {
  white-space: nowrap;
}

.modal-gacha-1 {
  .customer-modal {
    .ant-modal-content {
      max-width: 100%;
      box-shadow: 0px 0px 6px 0px #00000040;

      .ant-modal-close {
        right: 15px;
        top: 15px;

        .ant-modal-close-x {
          height: 24px;
          width: 24px;

          .anticon-close {
            font-size: 24px !important;
          }
        }

        @media screen and (max-width: 767px) {
          right: 5px;
          top: 5px;

          .ant-modal-close-x {
            height: 20px;
            width: 20px;

            .anticon-close {
              font-size: 20px !important;
            }
          }
        }
      }
    }
  }
}

.max-height {
  &.ant-spin {
    max-height: 100% !important;
  }
}
